.slider-container {
  height: 93%;
  padding: 15px 0;
}

.rc-slider-mark {
  width: 0;
  height: 0;
}

.rc-slider-vertical .rc-slider-rail {
  width: 14px;
}

.rc-slider-vertical .rc-slider-track {
  width: 14px;
}

.rc-slider-track {
  background-color: transparent;
}

.rc-slider-vertical .rc-slider-handle {
  margin-left: -2px;
}

.rc-slider-handle {
  background-color: #434343;
  border: solid 2px #434343;
  width: 18px;
  height: 18px;
}

.rc-slider-handle:hover {
  border-color: #434343;
}

.rc-slider-handle:active {
  border-color: #434343;
  box-shadow: 0 0 5px #434343;
}

.rc-slider-handle:focus {
  border-color: #434343;
  box-shadow: none;
}

.rc-slider-vertical {
  padding: 0;
  height: 96%;
}

/*
.rc-slider-vertical .rc-slider-handle {
  margin-bottom: -10px;
}
*/
